/* export default {
    apiKey: "AIzaSyCd8woLpYrTAOY2s6NiTMXBFswkqTqwYw4",
    authDomain: "semil-connect-test.firebaseapp.com",
    databaseURL: "https://semil-connect-test.firebaseio.com",
    projectId: "semil-connect-test",
    storageBucket: "semil-connect-test.appspot.com",
    messagingSenderId: "635077282945",
    appId: "1:635077282945:web:c7b74aa06a1a9ca0699180"
}; */

export default {
  apiKey: "AIzaSyDbKG9-Fa66eGjWLPkkzD6MBRLr1oQ1Ylo",
  authDomain: "teste-semil.firebaseapp.com",
  databaseURL: "https://teste-semil-default-rtdb.firebaseio.com",
  projectId: "teste-semil",
  storageBucket: "teste-semil.appspot.com",
  messagingSenderId: "665074556786",
  appId: "1:665074556786:web:8937d820ed42dd69c0c7eb",
  measurementId: "G-ZZ77S0T1QY",
};
