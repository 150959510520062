import React, { Component } from "react";
import DefaultLoader from "../components/DefaultLoader";
import IosSwitch from "../components/IosSwitch";
import { Card, MenuItem, Select } from "@material-ui/core";
import Firestore from "../api/firebase/Firestore";
import SessionHelper from "../helper/SessionHelper";
import Colors from "../constants/Colors";
import SATDashboard from "../components/SATDashboard";
import ComplaintDashboard from "../components/ComplaintDashboard";

export default class HomePage extends Component {
  state = {
    dashboard: null,
    defaultDashboard: "default",
    defaultDashboardCheckbox: false,
    idParam: null,
  };

  async componentDidMount() {
    await this.getParams();
    await this.getDefaultDashboard();
  }

  async getParams() {
    let defaultDashboard = "default";

    if (SessionHelper.getData().default_dashboard) {
      defaultDashboard = SessionHelper.getData().default_dashboard;
    }

    this.setState({
      defaultDashboard: defaultDashboard,
      dashboard: defaultDashboard,
    });
  }

  async getDefaultDashboard() {
    if (this.state.dashboard === this.state.defaultDashboard) {
      this.setState({ defaultDashboardCheckbox: true });
    } else {
      this.setState({ defaultDashboardCheckbox: false });
    }
  }

  async setDefaultDashboard() {
    let dashboard = this.state.dashboard;
    await Firestore.update(
      { default_dashboard: dashboard },
      "user",
      SessionHelper.getFirebaseAuth().uid
    );

    await this.setState({ defaultDashboard: dashboard });
    await this.getDefaultDashboard();
  }

  hasPermission(dashboard) {
    if (SessionHelper.getData().permission.routes[dashboard]) {
      return true;
    }

    return false;
  }

  render() {
    return this.state.loading ? (
      <DefaultLoader />
    ) : (
      <div
        style={{
          padding: 25,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card
          style={{
            paddingLeft: 25,
            paddingTop: 25,
            paddingBottom: 25,
            paddingRight: 25,
            width: "100%",
            height: 80,
            marginBottom: 8,
            display: "flex",
            flexDirection: "row",
            boxShadow: Colors.boxShadow,
          }}
          className={"header-actions-buttons"}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              paddingLeft: 10,
              paddingRight: 10,
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Select
              style={{ width: "100%", height: "100%", marginRight: 10 }}
              labelId="tipo"
              value={this.state.dashboard}
              onChange={async (v) => {
                await this.setState({ dashboard: v.target.value });
                this.getDefaultDashboard();
              }}
            >
              <MenuItem value={"default"}>{"DASHBOARD SAT"}</MenuItem>
              <MenuItem value={"complaint"}>{"DASHBOARD DENÚNCIAS"}</MenuItem>
            </Select>
            <IosSwitch
              onChange={(v) => {
                this.setDefaultDashboard();
              }}
              disabled={this.state.defaultDashboardCheckbox}
              label={window.screen.width < 1366 ? `` : `Padrão`}
              labelPlacement={"start"}
              checked={this.state.defaultDashboardCheckbox}
            />
          </div>
        </Card>
        {this.state.dashboard === "default" &&
        this.hasPermission("sat_dashboard") ? (
          <SATDashboard />
        ) : null}
        {this.state.dashboard === "complaint" &&
        this.hasPermission("complaint_dashboard") ? (
          <ComplaintDashboard />
        ) : null}
      </div>
    );
  }
}
