import React, { Component } from "react";
import Colors from "../constants/Colors";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/pt-br";
import Firestore from "../api/firebase/Firestore";
import { Button, MenuItem, TextareaAutosize } from "@material-ui/core";
import DefaultSelect from "../components/DefaultSelect";
import DefaultLoader from "../components/DefaultLoader";
import DefaultButton from "../components/DefaultButton";
import DefaultModal from "../components/DefaultModal";
import brLocale from "date-fns/locale/pt-BR";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import jsPDF from "jspdf";
import PDFHelper from "../helper/PDFHelper";
import CalendarEvent from "../components/CalendarEvent";
import CalendarToolbar from "./CalendarToolbar";
import TuneIcon from "@material-ui/icons/Tune";
import SlidingPane from "react-sliding-pane";
import CloseIcon from "@material-ui/icons/Close";
import { FormLabel } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AddIcon from "@material-ui/icons/Add";
import { toast } from "react-toastify";
import SessionHelper from "../helper/SessionHelper";
import CalendarEventPanel from "./CalendarEventPanel";
import CompanySelect from "./CompanySelect";

moment.locale("pt-br");
const localizer = momentLocalizer(moment);

export default class TechnicianCalendar extends Component {
  state = {
    loading: false,
    loadingModal: false,
    events: [],
    technicianDocs: [],
    companyDocs: [],
    satDocs: [],
    preSatDocs: [],
    selected_technician: "all",
    selected_company: "all",
    exportModal: false,
    exportDateStart: moment().startOf("month").toDate(),
    exportDateEnd: moment().endOf("month").toDate(),
    export_technicians: [],
    export_company: "",
    panelOpen: false,
    preScheduleModal: false,
    technicians: [],
    technicianStart: null,
    technicianEnd: null,
    showEventPanel: false,
    selectedEvent: null,
    company: null,
    obs: "",
  };

  async componentDidMount() {
    await this.setState({ loading: true });

    await this.getClients();
    await this.getTechnicians();
    await this.getSATs();
    await this.getPreSat();

    if (!this.props.filterTechnician) {
      await this.getCalendarEvents();
    } else {
      await this.setState({ selected_technician: this.props.filterTechnician });
      await this.filterEvents();
    }

    await this.setState({ loading: false });
  }

  getCalendarEvents() {
    let events = [];

    this.state.satDocs.forEach((sat, key) => {
      if (sat.technicians.length && sat.status !== "closed") {
        sat.technicians.forEach((technician, key) => {
          var tec =
            this.state.technicianDocs[
              this.state.technicianDocs.findIndex(
                (doc) => doc.id === technician.id_technician
              )
            ];
          if (!tec) {
            return;
          }
          let company =
            this.state.companyDocs[
              this.state.companyDocs.findIndex(
                (doc) => doc.id === sat.company_id
              )
            ];

          let event = {
            title: `${tec.name} - ${sat.title}`,
            start: technician.start.toDate(),
            end: technician.end.toDate(),
            allDay: false,
            id: sat.id,
            color: tec.color,
            company_id: sat.company_id,
            company_name: company.name,
            technician_id: technician.id_technician,
            technician_name: tec.name,
            appointment_number: sat.title,
            city: company.city,
            type: "sat",
            id_technicians: [technician.id_technician],
            id_user: sat.user_id,
          };

          events.push(event);
        });
      }
    });

    this.state.preSatDocs.forEach((sat, key) => {
      if (sat.technicians.length) {
        let company = sat.company_id
          ? this.state.companyDocs[
              this.state.companyDocs.findIndex(
                (doc) => doc.id === sat.company_id
              )
            ]
          : {};
        let shortNames = [];
        let names = [];

        sat.technicians.forEach((technician, key) => {
          var tec =
            this.state.technicianDocs[
              this.state.technicianDocs.findIndex(
                (doc) => doc.id === technician
              )
            ];

          if (tec) {
            shortNames.push(tec.name.split(" ")[0]);
            names.push(tec.name);
          }
        });

        let event = {
          title: `PRÉ SAT - ${shortNames}`,
          start: sat.start.toDate(),
          end: sat.end.toDate(),
          allDay: false,
          id: sat.id,
          technician_name: names.join(", "),
          appointment_number: "PRÉ SAT",
          type: "pre_sat",
          id_technicians: sat.technicians,
          id_user: sat.id_user,
          company_id: sat.company_id,
          company_name: company.name,
          obs: sat.obs,
        };

        events.push(event);
      }
    });

    this.setState({ events: events });
  }

  async filterEvents(filter, collection) {
    this.setState({ loading: true });

    let events = [];
    let query = await Firestore.customQuery("appointment")
      .where("status", "==", "open")
      .get();

    query.forEach((doc, key) => {
      if (
        !this.state.selected_company ||
        this.state.selected_company === "all" ||
        (this.state.selected_company &&
          doc.data().company_id === this.state.selected_company)
      ) {
        if (doc.data().technicians.length) {
          doc.data().technicians.forEach((item, key) => {
            if (
              !this.state.selected_technician ||
              this.state.selected_technician === "all" ||
              (this.state.selected_technician &&
                item.id_technician === this.state.selected_technician)
            ) {
              let tec =
                this.state.technicianDocs[
                  this.state.technicianDocs.findIndex(
                    (doc) => doc.id === item.id_technician
                  )
                ];
              let company =
                this.state.companyDocs[
                  this.state.companyDocs.findIndex(
                    (item) => item.id === doc.data().company_id
                  )
                ];

              let event = {
                title: `${tec.name} - ${doc.data().title}`,
                start: item.start.toDate(),
                end: item.end.toDate(),
                allDay: false,
                id: doc.id,
                color: tec.color,
                company_id: doc.company_id,
                company_name: company.name,
                technician_id: item.id_technician,
                technician_name: tec.name,
                appointment_number: doc.data().title,
                city: company.city,
              };

              events.push(event);
            }
          });
        }
      }
    });

    this.setState({ events: events, loading: false });
  }

  async getSATs() {
    let query = await Firestore.customQuery("appointment").get();
    let docs = [];

    query.forEach((doc, key) => {
      let data = doc.data();
      data.id = doc.id;

      docs.push(data);
    });

    this.setState({ satDocs: docs, loading: false });
  }

  async getPreSat() {
    let query = await Firestore.customQuery("pre_appointment").get();
    let docs = [];

    query.forEach((doc, key) => {
      let data = doc.data();
      data.id = doc.id;

      docs.push(data);
    });

    this.setState({ preSatDocs: docs, loading: false });
  }

  async getClients() {
    let query = await Firestore.customQuery("company").get();
    let docs = [];

    query.forEach((doc, key) => {
      let data = doc.data();
      data.id = doc.id;

      docs.push(data);
    });

    this.setState({ companyDocs: docs });
  }

  async getTechnicians() {
    let query = await Firestore.customQuery("technician").get();
    let docs = [];

    query.forEach((doc, key) => {
      let data = doc.data();
      data.id = doc.id;

      docs.push(data);
    });

    this.setState({ technicianDocs: docs, loading: false });
  }

  async export() {
    let doc = new jsPDF();
    let rectYOffset = 0;
    let img = await PDFHelper.imageToBase64(
      process.env.PUBLIC_URL + "/assets/image/logo.png"
    );

    doc = PDFHelper.header(
      doc,
      "AGENDA DE SAT",
      "DE " +
        moment(this.state.exportDateStart).format("DD/MM/YYYY HH:mm") +
        " ATÉ " +
        moment(this.state.exportDateEnd).format("DD/MM/YYYY HH:mm"),
      img
    );
    doc = PDFHelper.footer(doc);
    doc.text(
      doc.internal.pageSize.getWidth() - 20,
      290,
      "página " + doc.internal.getNumberOfPages()
    );

    this.state.events.forEach((event, key) => {
      if (
        moment(event.start).isSameOrAfter(moment(this.state.exportDateStart)) &&
        moment(event.end).isSameOrBefore(moment(this.state.exportDateEnd))
      ) {
        if (
          (this.state.export_company &&
            this.state.export_company === event.company_id) ||
          !this.state.export_company
        ) {
          if (
            (this.state.export_technicians.length &&
              this.state.export_technicians.includes(event.technician_id)) ||
            !this.state.export_technicians.length
          ) {
            doc.rect(
              15,
              24 + rectYOffset,
              doc.internal.pageSize.getWidth() - 27,
              18
            );

            doc.setFontType("bold");
            doc.text(event.title, 16, 28 + rectYOffset);

            let company =
              this.state.companyDocs[
                this.state.companyDocs.findIndex(
                  (item) => item.id === event.company_id
                )
              ];

            doc.setFontType("normal");
            doc.text("Cliente: " + company.name, 16, 28 + rectYOffset + 4);
            doc.text(
              "Início: " + moment(event.start).format("DD/MM/YYYY HH:mm"),
              16,
              28 + rectYOffset + 8
            );
            doc.text(
              "Fim: " + moment(event.end).format("DD/MM/YYYY HH:mm"),
              16,
              28 + rectYOffset + 12
            );

            rectYOffset += 21;

            if (rectYOffset > 220) {
              rectYOffset = 0;

              doc.addPage();
              doc.text(
                doc.internal.pageSize.getWidth() - 20,
                290,
                "página " + doc.internal.getNumberOfPages()
              );

              doc = PDFHelper.header(
                doc,
                "AGENDA DE SAT",
                "DE " +
                  moment(this.state.exportDateStart).format(
                    "DD/MM/YYYY HH:mm"
                  ) +
                  " ATÉ " +
                  moment(this.state.exportDateEnd).format("DD/MM/YYYY HH:mm"),
                img
              );
              doc = PDFHelper.footer(doc);
            }
          }
        }
      }
    });

    window.open(doc.output("bloburl"));
  }

  async addPreSchedule() {
    if (
      this.state.company &&
      this.state.technicians.length &&
      moment(this.state.technicianStart).isValid() &&
      moment(this.state.technicianEnd).isValid()
    ) {
      try {
        this.setState({ loadingModal: true });

        let data = {
          start: this.state.technicianStart,
          end: this.state.technicianEnd,
          technicians: this.state.technicians,
          date: new Date(),
          id_user: SessionHelper.getFirebaseAuth().uid,
          company_id: this.state.company,
          obs: this.state.obs,
        };

        await Firestore.insert(data, "pre_appointment");
        await this.getPreSat();
        await this.getCalendarEvents();

        this.setState({
          technicians: [],
          technicianStart: null,
          technicianEnd: null,
          preScheduleModal: false,
          loadingModal: false,
          company: null,
          obs: "",
        });
        toast.success("Pré agendamento realizado com sucesso");
      } catch (error) {
        toast.error("Houve um problema ao agendar o evento");
        this.setState({ loadingModal: false });
      }
    } else {
      toast.warn("Preencha todos os campos");
    }
  }

  exportModal() {
    return (
      <div>
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              style={{ width: "100%", marginTop: 15 }}
              invalidDateMessage={false}
              format={"dd/MM/yyyy HH:mm"}
              autoOk={true}
              label="Início"
              cancelLabel={"Cancelar"}
              okLabel={"Confirmar"}
              onChange={(v) => {
                this.setState({ exportDateStart: v });
              }}
              value={this.state.exportDateStart}
            />
            <div style={{ marginRight: 5, marginLeft: 5 }} />
            <KeyboardDateTimePicker
              style={{ width: "100%", marginTop: 15 }}
              invalidDateMessage={false}
              format={"dd/MM/yyyy HH:mm"}
              autoOk={true}
              label="Fim"
              cancelLabel={"Cancelar"}
              okLabel={"Confirmar"}
              onChange={(v) => {
                this.setState({ exportDateEnd: v });
              }}
              minDate={this.state.exportDateStart || new Date()}
              value={this.state.exportDateEnd}
            />
          </MuiPickersUtilsProvider>
        </div>

        <div style={{ paddingTop: 25 }}>
          <DefaultSelect
            search={true}
            searchField={"name"}
            id={"company_selection"}
            valueField={"id"}
            displayField={"name"}
            onChange={(v) => {
              this.setState({ export_company: v.target.value });
            }}
            value={this.state.export_company}
            docs={this.state.companyDocs}
            label={"Selecionar empresa"}
          />
        </div>

        <div style={{ paddingTop: 25 }}>
          <DefaultSelect
            multiple={true}
            search={true}
            searchField={"name"}
            id={"technician_selection"}
            valueField={"id"}
            displayField={"name"}
            onChange={(v) => {
              this.setState({ export_technicians: v.target.value });
            }}
            value={this.state.export_technicians}
            docs={this.state.technicianDocs}
            label={"Selecionar técnicos"}
          />
        </div>

        <div
          style={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 50,
            width: "100%",
          }}
        >
          <Button
            onClick={() => {
              this.export();
            }}
            style={{
              fontWeight: "bold",
              backgroundColor: Colors.semil.green,
              color: "#fff",
              width: "100%",
            }}
            variant={"contained"}
          >
            {"Gerar PDF"}
          </Button>
        </div>
      </div>
    );
  }

  preScheduleModal() {
    return (
      <div>
        <div style={{ marginTop: 20 }} />
        <CompanySelect
          onChange={(v) => {
            this.setState({ company: v.target.value });
          }}
          value={this.state.company}
          label={"Selecionar empresa"}
        />
        <div style={{ marginTop: 20 }} />
        <DefaultSelect
          multiple={true}
          search={true}
          searchField={"name"}
          id={"technician_attatchment"}
          valueField={"id"}
          value={this.state.technicians}
          displayField={"name"}
          onChange={(v) => {
            this.setState({ technicians: v.target.value });
          }}
          docs={this.state.technicianDocs}
          label={"Técnicos"}
        />
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 20,
          }}
        >
          <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              style={{ width: "100%" }}
              invalidDateMessage={false}
              format={"dd/MM/yyyy HH:mm"}
              autoOk={true}
              label="Início"
              cancelLabel={"Cancelar"}
              okLabel={"Confirmar"}
              onChange={(v) => {
                this.setState({ technicianStart: v });
              }}
              value={this.state.technicianStart}
            />
            <div style={{ marginLeft: 4, marginRight: 4 }} />
            <KeyboardDateTimePicker
              style={{ width: "100%" }}
              invalidDateMessage={false}
              format={"dd/MM/yyyy HH:mm"}
              autoOk={true}
              label="Fim"
              cancelLabel={"Cancelar"}
              okLabel={"Confirmar"}
              onChange={(v) => {
                this.setState({ technicianEnd: v });
              }}
              minDate={this.state.technicianStart || new Date()}
              value={this.state.technicianEnd}
            />
          </MuiPickersUtilsProvider>
        </div>
        <FormLabel
          style={{ paddingBottom: 18, paddingTop: 10 }}
          component="legend"
        >
          Observação
        </FormLabel>
        <TextareaAutosize
          rowsMax={8}
          rowsMin={8}
          placeholder="Digite algo..."
          style={{
            width: "100%",
            fontSize: "inherit",
            padding: 10,
            borderRadius: 8,
            border: `0.7px solid grey`,
          }}
          onBlur={(evt) => {
            this.setState({ obs: evt.target.value });
          }}
        />
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 50,
          }}
        >
          <Button
            onClick={() => {
              this.addPreSchedule();
            }}
            style={{
              fontWeight: "bold",
              backgroundColor: Colors.semil.green,
              color: "#fff",
              width: "48%",
              marginRight: "2%",
            }}
            variant={"contained"}
          >
            {"CONFIRMAR"}
          </Button>
          <Button
            onClick={() => {
              this.setState({ preScheduleModal: false });
            }}
            style={{ width: "48%", fontWeight: "bold", marginLeft: "2%" }}
            variant={"contained"}
          >
            {"CANCELAR"}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return this.state.loading ? (
      <DefaultLoader />
    ) : (
      <div>
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {this.props.export ? (
            <div>
              <DefaultButton
                leftIcon={<AddIcon />}
                onClick={() => {
                  this.setState({ preScheduleModal: true });
                }}
                title={"Pré Agendamento"}
              />
            </div>
          ) : null}

          {!this.props.noFilter && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <DefaultButton
                onClick={() => {
                  this.setState({ exportModal: true });
                }}
                width={50}
                leftIcon={<CloudDownloadIcon style={{ marginLeft: 8 }} />}
                title={""}
              />
              <div style={{ marginLeft: 8 }} />
              <DefaultButton
                width={50}
                leftIcon={<TuneIcon style={{ marginLeft: 8 }} />}
                onClick={() => {
                  this.setState({ panelOpen: !this.state.panelOpen });
                }}
                title={""}
              />
            </div>
          )}
        </div>

        <Calendar
          className={"technician-calendar"}
          messages={{
            agenda: "Agenda",
            today: "Hoje",
            month: "Mês",
            week: "Semana",
            day: "Dia",
            next: "Próximo",
            previous: "Voltar",
            noEventsInRange: "Nenhum evento encontrado para essas datas",
            allDay: "Dia Todo",
            date: "Data",
            event: "Evento",
            time: "Hora",
            showMore: (count) => `+${count} eventos`,
          }}
          localizer={localizer}
          events={this.state.events}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={(event) => {
            var technitianColor = event.color;
            if (!technitianColor) {
              technitianColor = Colors.technician.defaultColor;
            }
            var style = {
              backgroundColor: technitianColor,
              borderRadius: "0px",
              opacity: 0.8,
              color: "black",
              border: "0px",
              display: "block",
            };
            return {
              style: style,
            };
          }}
          style={{ height: this.props.height ? this.props.height : 650 }}
          onSelectEvent={(evt) => {
            this.setState({ showEventPanel: true, selectedEvent: evt });
          }}
          components={{
            toolbar: (props) => <CalendarToolbar {...props} />,
            event: CalendarEvent,
          }}
          tooltipAccessor={(e) => {
            return "";
          }}
        />
        <SlidingPane
          className={"right-panel"}
          isOpen={this.state.panelOpen}
          title="Filtros"
          closeIcon={<CloseIcon style={{ width: 30, marginTop: 5 }} />}
          width={window.screen.width < 1080 ? "80%" : "40%"}
          onRequestClose={() => {
            this.setState({ panelOpen: false });
          }}
        >
          <FormLabel
            style={{ paddingBottom: 18, paddingTop: 18 }}
            component="legend"
          >
            Filtrar
          </FormLabel>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <DefaultSelect
              search={true}
              allItems={<MenuItem value={"all"}>{"TODOS OS TÉNICOS"}</MenuItem>}
              searchField={"name"}
              id={"technician_selection"}
              valueField={"id"}
              displayField={"name"}
              onChange={(v) => {
                this.setState({ selected_technician: v.target.value });
                this.filterEvents(v.target.value, "technician");
              }}
              value={this.state.selected_technician || "all"}
              docs={this.state.technicianDocs}
              label={"Filtrar Técnico"}
            />
            <div style={{ marginLeft: 5, marginRight: 5 }} />
            <DefaultSelect
              search={true}
              allItems={
                <MenuItem value={"all"}>{"TODOS OS CLIENTES"}</MenuItem>
              }
              searchField={"name"}
              id={"company_filter_selection"}
              valueField={"id"}
              displayField={"name"}
              onChange={(v) => {
                this.setState({ selected_company: v.target.value });
                this.filterEvents(v.target.value, "company");
              }}
              value={this.state.selected_company || "all"}
              docs={this.state.companyDocs}
              label={"Filtrar Cliente"}
            />
          </div>
        </SlidingPane>

        {this.state.showEventPanel ? (
          <CalendarEventPanel
            show={this.state.showEventPanel}
            event={this.state.selectedEvent}
            onRequestClose={async () => {
              this.setState({ showEventPanel: false, selectedEvent: null });

              await this.getSATs();
              await this.getPreSat();

              this.getCalendarEvents();
            }}
          />
        ) : null}

        <DefaultModal
          loading={this.state.loadingModal}
          content={this.exportModal()}
          title={"Exportar Agenda"}
          onClose={() => {
            this.setState({ exportModal: false });
          }}
          open={this.state.exportModal}
        />
        <DefaultModal
          loading={this.state.loadingModal}
          content={this.preScheduleModal()}
          title={"Pré Agendamento"}
          onClose={() => {
            this.setState({ preScheduleModal: false });
          }}
          open={this.state.preScheduleModal}
        />
      </div>
    );
  }
}
