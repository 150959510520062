import React, { Component } from "react";
import DefaultLoader from "../components/DefaultLoader";
import Colors from "../constants/Colors";
import { Card, IconButton, Tooltip } from "@material-ui/core";
import Firestore from "../api/firebase/Firestore";
import moment from "moment";
import DefaultInput from "../components/DefaultInput";
import { Doughnut, Bar } from "react-chartjs-2";
import brLocale from "date-fns/locale/pt-BR";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CalendarEvent from "../components/CalendarEvent";

moment.locale("pt-br");
const localizer = momentLocalizer(moment);

export default class SATDashboard extends Component {
  state = {
    loading: true,
    satDocs: [],
    technicianDocs: [],
    companyDocs: [],
    SATChart: [],
    SATChart_start: moment().startOf("month").toDate(),
    SATChart_end: moment().endOf("month").toDate(),
    technicianSATChart: {
      labels: [],
      datasets: [
        {
          label: "Técnico",
          backgroundColor: "#36A2EB",
          borderWidth: 1,
          data: [],
        },
      ],
    },
    technicianSATChart_start: moment().startOf("month").toDate(),
    technicianSATChart_end: moment().endOf("month").toDate(),
    technicianHourChart: {},
    technicianHourChart_start: moment().startOf("week").toDate(),
    technicianHourChart_end: moment().endOf("week").toDate(),
    events: [],
  };

  async getSATs() {
    let query = await Firestore.customQuery("appointment").get();
    let docs = [];

    query.forEach((doc, key) => {
      docs.push(doc.data());
    });

    this.setState({ satDocs: docs });
  }

  async getTechnicians() {
    let query = await Firestore.customQuery("technician").get();
    let docs = [];

    query.forEach((doc, key) => {
      let data = doc.data();
      data.id = doc.id;

      docs.push(data);
    });

    this.setState({ technicianDocs: docs });
  }

  async getClients() {
    let query = await Firestore.customQuery("company").get();
    let docs = [];

    query.forEach((doc, key) => {
      let data = doc.data();
      data.id = doc.id;

      docs.push(data);
    });

    this.setState({ companyDocs: docs });
  }

  async componentDidMount() {
    this.setState({ loading: true });

    await this.getSATs();
    await this.getClients();
    await this.getTechnicians();

    await this.SATChart();
    await this.technicianSATChart();
    await this.technicianHourChart();
    await this.getCalendarEvents();

    this.setState({ loading: false });
  }

  async SATChart() {
    let data = {
      open: 0,
      closed: 0,
      archived: 0,
    };

    this.state.satDocs.forEach((sat, key) => {
      if (
        moment(this.state.SATChart_start).isValid() &&
        moment(sat.start_date.toDate()).isSameOrAfter(
          moment(this.state.SATChart_start)
        ) &&
        moment(this.state.SATChart_end).isValid() &&
        moment(sat.start_date.toDate()).isSameOrBefore(
          moment(this.state.SATChart_end)
        )
      ) {
        if (sat.status === "open") {
          data.open++;
        }
      }

      if (
        moment(this.state.SATChart_start).isValid() &&
        sat.end_date &&
        moment(sat.end_date.toDate()).isSameOrAfter(
          moment(this.state.SATChart_start)
        ) &&
        moment(this.state.SATChart_end).isValid() &&
        moment(sat.end_date.toDate()).isSameOrBefore(
          moment(this.state.SATChart_end)
        )
      ) {
        if (sat.status === "closed") {
          data.closed++;
        }

        if (sat.status === "archived") {
          data.archived++;
        }
      }
    });

    this.setState({ SATChart: [data.open, data.closed, data.archived] });
  }

  async technicianSATChart() {
    let data = {
      labels: [],
      datasets: [
        {
          label: "Técnico",
          backgroundColor: "#36A2EB",
          borderWidth: 1,
          data: [],
        },
      ],
    };

    let technicianChart = {};

    this.state.satDocs.forEach((sat, key) => {
      if (sat.technicians.length) {
        sat.technicians.forEach((technician, key) => {
          let technicianDoc =
            this.state.technicianDocs[
              this.state.technicianDocs.findIndex(
                (item) => item.id === technician.id_technician
              )
            ];

          if (!technicianChart[technician.id_technician] && technicianDoc) {
            technicianChart[technician.id_technician] = 0;
            data.labels.push(technicianDoc?.name);
          }

          if (
            moment(this.state?.technicianSATChart_start).isValid() &&
            moment(sat.start_date?.toDate()).isSameOrAfter(
              moment(this.state?.SATChart_start)
            ) &&
            moment(this.state.technicianSATChart_end).isValid() &&
            moment(sat.start_date.toDate()).isSameOrBefore(
              moment(this.state.technicianSATChart_end)
            )
          ) {
            if (sat.status === "closed" || sat.status === "archived") {
              technicianChart[technician.id_technician]++;
            }
          }
        });
      }
    });

    Object.keys(technicianChart).forEach((value, key) => {
      data.datasets[0].data.push(technicianChart[value]);
    });

    this.setState({ technicianSATChart: data });
  }

  async technicianHourChart() {
    let data = {
      labels: [],
      datasets: [
        {
          label: "Horas Trabalhadas",
          backgroundColor: "#FFCE56",
          borderWidth: 1,
          data: [],
        },
      ],
    };

    let technicianChart = {};

    this.state.satDocs.forEach((sat, key) => {
      if (sat.technicianHours && sat.technicianHours.length) {
        sat.technicianHours.forEach((hour, key) => {
          let technicianDoc =
            this.state.technicianDocs[
              this.state.technicianDocs.findIndex(
                (item) => item.user_id === hour.user_id
              )
            ];

          if (technicianDoc && technicianDoc.id) {
            if (technicianChart[technicianDoc.id] === undefined) {
              technicianChart[technicianDoc.id] = 0;
              data.labels.push(technicianDoc.name);
            }

            if (hour.endDate) {
              if (
                moment(this.state.technicianHourChart_start).isValid() &&
                moment(hour.startDate.toDate()).isSameOrAfter(
                  moment(this.state.technicianHourChart_start)
                ) &&
                moment(this.state.technicianHourChart_end).isValid() &&
                moment(hour.endDate.toDate()).isSameOrBefore(
                  moment(this.state.technicianHourChart_end)
                )
              ) {
                technicianChart[technicianDoc.id] += hour.minutesWorked;
              }
            }
          }
        });
      }
    });

    Object.keys(technicianChart).forEach((value, key) => {
      let hours = technicianChart[value] / 60;

      data.datasets[0].data.push(hours.toFixed(2));
    });

    this.setState({ technicianHourChart: data });
  }

  getCalendarEvents() {
    let events = [];

    this.state.satDocs.forEach((sat, key) => {
      if (sat.technicians.length && sat.status !== "closed") {
        sat.technicians.forEach((technician, key) => {
          var tec = this.state.technicianDocs.find(
            (el) => el.id === technician.id_technician
          );
          if (!tec) {
            return;
          }

          let company = this.state.companyDocs.find(
            (el) => el.id === sat.company_id
          );

          let event = {
            title: `${tec?.name || "Técnico"} - ${sat?.title}`,
            start: technician.start.toDate(),
            end: technician.end.toDate(),
            allDay: false,
            id: sat.id,
            color: tec.color,
            company_id: sat.company_id,
            company_name: company.name,
            technician_id: technician.id_technician,
            technician_name: tec.name,
            appointment_number: sat.title,
            city: company.city,
          };

          events.push(event);
        });
      }
    });

    this.setState({ events: events });
  }

  renderEmptyChart() {
    return (
      <div style={{ position: "relative", bottom: 100, color: "grey" }}>
        {"Nenhum dado a ser exibido."}
      </div>
    );
  }

  render() {
    return this.state.loading ? (
      <DefaultLoader />
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 10,
        }}
      >
        <Card
          style={{
            padding: 25,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "calc(50% - 10px)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: 20 }}>{"Assistência Técnica"}</div>
            <Tooltip
              onClick={async () => {
                await this.getSATs();
                await this.SATChart();
              }}
              title="Atualizar"
            >
              <IconButton>
                <RefreshIcon style={{}} />
              </IconButton>
            </Tooltip>
          </div>
          <Doughnut
            height={95}
            options={{ maintainAspectRatio: true }}
            data={{
              labels: ["Em aberto", "Finalizadas", "Arquivadas"],
              datasets: [
                {
                  data: this.state.SATChart,
                  backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                },
              ],
            }}
          />
          {!this.state.SATChart[0] > 0 &&
          !this.state.SATChart[1] > 0 &&
          !this.state.SATChart[2] > 0
            ? this.renderEmptyChart()
            : null}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              alignSelf: "flex-end",
              marginTop: 20,
            }}
          >
            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: "100%", padding: 15 }}
                invalidDateMessage={false}
                format={"dd/MM/yyyy"}
                autoOk={true}
                cancelLabel={"Cancelar"}
                okLabel={"Confirmar"}
                onChange={async (v) => {
                  await this.setState({ SATChart_start: v });
                  this.SATChart();
                }}
                value={this.state.SATChart_start}
              />
            </MuiPickersUtilsProvider>
            <div style={{ color: "grey" }}>{"até"}</div>
            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: "100%", padding: 15 }}
                invalidDateMessage={false}
                format={"dd/MM/yyyy"}
                autoOk={true}
                cancelLabel={"Cancelar"}
                okLabel={"Confirmar"}
                onChange={async (v) => {
                  await this.setState({ SATChart_end: v });
                  this.SATChart();
                }}
                value={this.state.SATChart_end}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Card>

        <Card
          style={{
            padding: 25,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: 20 }}>{"SAT Finalizada por Técnico"}</div>
            <Tooltip
              onClick={async () => {
                await this.getSATs();
                await this.getTechnicians();
                await this.technicianSATChart();
              }}
              title="Atualizar"
            >
              <IconButton>
                <RefreshIcon style={{}} />
              </IconButton>
            </Tooltip>
          </div>

          <Bar
            height={95}
            options={{
              maintainAspectRatio: true,
              scales: {
                yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }],
              },
            }}
            data={this.state.technicianSATChart}
          />

          {!this.state.technicianSATChart.datasets[0].data.length
            ? this.renderEmptyChart()
            : null}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              alignSelf: "flex-end",
              marginTop: 20,
            }}
          >
            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: "100%", padding: 15 }}
                invalidDateMessage={false}
                format={"dd/MM/yyyy"}
                autoOk={true}
                cancelLabel={"Cancelar"}
                okLabel={"Confirmar"}
                onChange={async (v) => {
                  await this.setState({ technicianSATChart_start: v });
                  this.technicianSATChart();
                }}
                value={this.state.technicianSATChart_start}
              />
            </MuiPickersUtilsProvider>
            <div style={{ color: "grey" }}>{"até"}</div>
            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: "100%", padding: 15 }}
                invalidDateMessage={false}
                format={"dd/MM/yyyy"}
                autoOk={true}
                cancelLabel={"Cancelar"}
                okLabel={"Confirmar"}
                onChange={async (v) => {
                  await this.setState({ technicianSATChart_end: v });
                  this.technicianSATChart();
                }}
                value={this.state.technicianSATChart_end}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Card>

        <Card
          style={{
            padding: 25,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "calc(50% - 10px)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: 20 }}>{"Apontamento de Horas"}</div>
            <Tooltip
              onClick={async () => {
                await this.getSATs();
                await this.getTechnicians();
                await this.technicianHourChart();
              }}
              title="Atualizar"
            >
              <IconButton>
                <RefreshIcon style={{}} />
              </IconButton>
            </Tooltip>
          </div>

          <Bar
            height={95}
            options={{
              maintainAspectRatio: true,
              scales: {
                yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }],
              },
            }}
            data={this.state.technicianHourChart}
          />
          {!this.state.technicianHourChart.datasets[0].data.length
            ? this.renderEmptyChart()
            : null}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              alignSelf: "flex-end",
              marginTop: 20,
            }}
          >
            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: "100%", padding: 15 }}
                invalidDateMessage={false}
                format={"dd/MM/yyyy"}
                autoOk={true}
                cancelLabel={"Cancelar"}
                okLabel={"Confirmar"}
                onChange={async (v) => {
                  await this.setState({ technicianHourChart_start: v });
                  this.technicianHourChart();
                }}
                value={this.state.technicianHourChart_start}
              />
            </MuiPickersUtilsProvider>
            <div style={{ color: "grey" }}>{"até"}</div>
            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: "100%", padding: 15 }}
                invalidDateMessage={false}
                format={"dd/MM/yyyy"}
                autoOk={true}
                cancelLabel={"Cancelar"}
                okLabel={"Confirmar"}
                onChange={async (v) => {
                  await this.setState({ technicianHourChart_end: v });
                  this.technicianHourChart();
                }}
                value={this.state.technicianHourChart_end}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Card>

        <Card
          style={{
            padding: 25,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: 20 }}>{"Agenda de Técnicos"}</div>
            <Tooltip
              onClick={async () => {
                await this.getSATs();
                await this.getTechnicians();
                await this.getClients();
                await this.getCalendarEvents();
              }}
              title="Atualizar"
            >
              <IconButton>
                <RefreshIcon style={{}} />
              </IconButton>
            </Tooltip>
          </div>

          <Calendar
            messages={{
              agenda: "Agenda",
              today: "Hoje",
              month: "Mês",
              week: "Semana",
              day: "Dia",
              next: "Próximo",
              previous: "Voltar",
              noEventsInRange: "Nenhum evento encontrado para essas datas",
              allDay: "Dia Todo",
              date: "Data",
              event: "Evento",
              time: "Hora",
              showMore: (count) => `+${count} eventos`,
            }}
            localizer={localizer}
            events={this.state.events}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={(event) => {
              var technitianColor = event.color;
              if (!technitianColor) {
                technitianColor = Colors.technician.defaultColor;
              }
              var style = {
                backgroundColor: technitianColor,
                borderRadius: "0px",
                opacity: 0.8,
                color: "black",
                border: "0px",
                display: "block",
              };
              return {
                style: style,
              };
            }}
            defaultView={"agenda"}
            views={["agenda"]}
            style={{ height: "100%", width: "100%" }}
            onSelectEvent={(evt) => {
              this.redirectToSAT(evt);
            }}
            components={{
              event: CalendarEvent,
            }}
            tooltipAccessor={(e) => {
              return "";
            }}
          />
        </Card>
      </div>
    );
  }
}
