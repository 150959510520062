import React, { Component } from "react";
import TreeView from "../components/TreeView";
import Colors from "../constants/Colors";
import DefaultInput from "../components/DefaultInput";
import {
  AccountTree,
  ArrowDropDown,
  AspectRatio,
  Description,
  List,
  Pause,
  PlayArrow,
  Search,
  Stop,
  Visibility,
  People,
  AccessTime,
  Print,
  FilterList,
  FilterNone,
} from "@material-ui/icons";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { Checkbox, IconButton, ListItem, Tooltip } from "@material-ui/core";
import DefaultButton from "../components/DefaultButton";
import { Menu, MenuItem } from "@material-ui/core";
import DefaultLoader from "../components/DefaultLoader";
import DefaultModal from "../components/DefaultModal";
import moment from "moment";
import * as XLSX from "xlsx";
import Functions from "../constants/Functions";

export default class OFPage extends Component {
  state = {
    loading: true,
    search: "",
    atividades: [],
    eng_recursos: [],
    ofs: [],
    ofList: [],
    items: [],
    tasks: [],
    selectedOf: null,
    selectedItem: null,
    groupByCode: false,
    searchTimeout: null,
    loadingOf: false,
    loadingOfItems: false,
    loadingItemTasks: false,
    expandedAll: false,
    menuTarget: null,
    menuTargetStatus: null,
    menuTargetEngRecurso: null,
    hoveredTask: null,
    appointmentModal: false,
    loadingModal: false,
    appointments: [],
    status: [
      { value: "E", descricao: "Encerrado", checked: false },
      { value: "P", descricao: "Produção", checked: false },
    ],
  };

  async componentDidMount() {
    this.setState({ loading: false });
  }

  async getAtividades(sequencia) {
    try {
      this.setState({ loadingOf: true, ofs: [], items: [], tasks: [] });

      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          method: "getAtividades",
          body: { id: sequencia },
        }),
      };

      let request = await fetch(
        `${Functions.getFunctionEndPoint()}/ordOrdem`,
        options
      );
      let json = await request.json();

      this.setState({ atividades: json });
    } catch (error) {}
  }

  async getAtividadesEngRecursos(id) {
    try {
      this.setState({ loadingOf: true, ofs: [], items: [], tasks: [] });

      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          method: "getAtividadesEngRecursos",
          body: { id },
        }),
      };

      let request = await fetch(
        `${Functions.getFunctionEndPoint()}/ordOrdem`,
        options
      );
      let json = await request.json();

      this.setState({ eng_recursos: json });
    } catch (error) {}
  }

  async printOfs(ofs) {
    try {
      this.setState({ loading: true });

      var filename = "Of.xlsx";
      var data = [["Código", "Descrição", "Quantidade", "Status"]];

      ofs.forEach((item, key) => {
        if (item.item) {
          item = item.item;
        }

        data.push([
          item.ORD_OrdemProduto.replace(/\s+/g, ""),
          item.PRO_Descricao,
          item.ORD_OrdemQuantidade,
          item.ORD_OrdemSituacao,
        ]);
      });

      var ws_name = "Itens";
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(data);

      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, filename);

      this.setState({ loading: false });
      toast.success("Exportado com sucesso");
    } catch (error) {
      this.setState({ loading: false });
      toast.error("Houve um problema ao exportar");
    }
  }

  async getOfList() {
    if (isNaN(parseInt(this.state.filteredOfsIds))) {
      toast.info("Para ver como lista filtre por atividades");
      return;
    }

    await this.setState({ showList: !this.state.showList });

    if (!this.state.showList) {
      await this.setState({ ofList: [] });
      return;
    }

    try {
      this.setState({ loadingOf: true, ofList: [] });

      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          method: "getOrdOrdemFilteredList",
          body: {
            filteredOfsIds: this.state.filteredOfsIds,
            id: parseInt(this.state.search),
          },
        }),
      };

      let request = await fetch(
        `${Functions.getFunctionEndPoint()}/ordOrdem`,
        options
      );
      let json = await request.json();

      this.setState({ ofList: json }, () => {
        this.setState({ loadingOf: false });
      });
    } catch (error) {
      this.setState({ loadingOf: false });
      toast.error("Houve um problema ao buscar a OF");
    }
  }

  async getOf(search) {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    this.setState({ search });

    if (search && search.length) {
      let timeout = setTimeout(async () => {
        if (isNaN(parseInt(search))) {
          toast.warn("A busca deve ser um número");
          return;
        }

        try {
          this.setState({ loadingOf: true, ofs: [], items: [], tasks: [] });

          const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              method: "getOrdOrdemTree",
              body: { id: parseInt(search) },
            }),
          };

          let request = await fetch(
            `${Functions.getFunctionEndPoint()}/ordOrdem`,
            options
          );
          let json = await request.json();

          await this.getAtividades(parseInt(search));
          await this.getAtividadesEngRecursos(parseInt(search));

          let atividades = this.state.atividades;
          let eng_recursos = this.state.eng_recursos;

          atividades.forEach((atividade, key) => {
            atividade.checked = false;
          });

          eng_recursos.forEach((atividade, key) => {
            eng_recursos.checked = false;
          });

          this.setState({ loadingOf: false, ofs: json, atividades });
        } catch (error) {
          this.setState({ loadingOf: false });
          toast.error("Houve um problema ao buscar a OF");
        }
      }, 300);

      this.setState({ searchTimeout: timeout });
    }
  }

  getOfItems = async (of) => {
    let sequencia = of.item
      ? of.item.ORD_OrdemSequencia
      : of.ORD_OrdemSequencia;

    if (isNaN(parseInt(sequencia))) {
      toast.warn("A sequência deve ser um número");
      return;
    }

    try {
      let selectedOf = of.item ? { ...of.item } : { ...of };
      delete selectedOf.children;

      this.setState(
        { loadingOfItems: true, items: [], tasks: [], selectedOf },
        async () => {
          const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              method: "getOrdOrdemItem",
              body: { sequence: parseInt(sequencia) },
            }),
          };

          let request = await fetch(
            `${Functions.getFunctionEndPoint()}/ordOrdem`,
            options
          );
          let items = await request.json();

          let atividades = this.state.atividades;
          let atv_ids = [];

          atividades.forEach((atividade, key) => {
            if (atividade.checked) {
              atv_ids.push(atividade.item.ORD_OrdemPrvAtvCodigo);
            }
          });

          if (atv_ids.length) {
            const options = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                method: "getFilteredItemAtv",
                body: {
                  id: parseInt(this.state.search),
                  sequence: parseInt(sequencia),
                  atv_ids,
                },
              }),
            };

            let request = await fetch(
              `${Functions.getFunctionEndPoint()}/ordOrdem`,
              options
            );
            let json = await request.json();
            let ids = [];

            json.forEach((item, key) => {
              ids.push(item.item.ORD_OrdemPrvItemSeq);
            });

            items = this.filterTree(items, ids, "ORD_OrdemPrvItemSeq");
          }

          this.setState({ loadingOfItems: false, items: items });
        }
      );
    } catch (error) {
      this.setState({ loadingOf: false });
      toast.error("Houve um problema ao buscar a OF");
    }
  };

  async getItemTasks(item) {
    const sequencia = item.item.ORD_OrdemSequencia;

    if (isNaN(parseInt(sequencia))) {
      toast.warn("A sequência deve ser um número");
      return;
    }

    try {
      this.setState(
        { loadingItemTasks: true, tasks: [], selectedItem: item.item },
        async () => {
          const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              method: "getOrdOrdemItemAtv",
              body: { sequence: parseInt(sequencia) },
            }),
          };

          let request = await fetch(
            `${Functions.getFunctionEndPoint()}/ordOrdem`,
            options
          );
          let json = await request.json();

          this.setState({ loadingItemTasks: false, tasks: json });
        }
      );
    } catch (error) {
      this.setState({ loadingOf: false });
      toast.error("Houve um problema ao buscar a OF");
    }
  }

  getFiltersApplied() {
    let atividades = this.state.atividades;
    let atv_ids = [];

    let status = this.state.status;
    let situacao_ids = [];

    let eng_recursos = this.state.eng_recursos;
    let eng_recursos_ids = [];

    atividades.forEach((atividade, key) => {
      if (atividade.checked) {
        atv_ids.push(atividade.item.ORD_OrdemPrvAtvCodigo);
      }
    });

    status.forEach((item, key) => {
      if (item.checked) {
        situacao_ids.push(`'${item.value}'`);
      }
    });

    eng_recursos.forEach((item, key) => {
      if (item.checked) {
        eng_recursos_ids.push(item.item.ENG_RecursoCodigo);
      }
    });

    return {
      atv_ids,
      situacao_ids,
      eng_recursos_ids,
    };
  }

  async filterOf() {
    try {
      this.setState({
        menuTarget: null,
        menuTargetStatus: null,
        menuTargetEngRecurso: null,
        loadingOf: true,
        items: [],
        tasks: [],
        filteredOfsIds: [],
        selectedOf: null,
        selectedItem: null,
      });

      let { atv_ids, situacao_ids, eng_recursos_ids } =
        this.getFiltersApplied();

      if (atv_ids.length < 1)
        this.setState({ showList: false, expandedAll: false });

      let ofs = this.state.ofs;

      if (atv_ids.length || situacao_ids.length || eng_recursos_ids.length) {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            method: "getFilteredOf",
            body: {
              id: parseInt(this.state.search),
              atv_ids: atv_ids,
              situacao_ids: situacao_ids,
              eng_recursos_ids,
            },
          }),
        };

        let request = await fetch(
          `${Functions.getFunctionEndPoint()}/ordOrdem`,
          options
        );
        let json = await request.json();
        let ids = [];

        if (json) {
          json.forEach((item, key) => {
            ids.push(item.item.ORD_OrdemNumero);
          });

          this.setState({ filteredOfsIds: ids });

          ofs = this.filterTree(ofs, ids, "ORD_OrdemNumero");

          this.handleExpand(ofs, true);
        } else {
          await this.getOf(this.state.search);
          toast.error("A consulta falhou");
        }
      } else {
        await this.getOf(this.state.search);
      }

      this.setState({ loadingOf: false });
    } catch (error) {
      this.setState({ loadingOf: false, ofs: [] });
    }
  }

  async handleAppointmentsModal(cod, seq) {
    try {
      this.setState({
        appointmentModal: true,
        loadingModal: true,
        appointments: [],
      });

      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          method: "getOrdOrdemItemAtvApt",
          body: {
            sequence: parseInt(this.state.selectedItem.ORD_OrdemSequencia),
            atv_seq: seq,
            atv_cod: cod,
          },
        }),
      };

      let request = await fetch(
        `${Functions.getFunctionEndPoint()}/ordOrdem`,
        options
      );
      let json = await request.json();

      this.setState({ loadingModal: false, appointments: json });
    } catch (error) {
      toast.error("Houve um problema ao buscar os apontamentos");
      this.setState({ loadingModal: false });
    }
  }

  renderEmpty(text) {
    return (
      <p
        style={{
          color: "grey",
          fontSize: 13,
          textAlign: "center",
          paddingTop: "20%",
        }}
      >
        {text}
      </p>
    );
  }

  renderSelectIcon = (selected, doc) => {
    return (
      <div
        onClick={(evt) => {
          evt.stopPropagation();
          this.getOfItems(doc);
        }}
        style={{
          marginRight: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title={"Carregar Itens"}>
          <Description
            style={{
              cursor: "pointer",
              color: selected ? "white" : Colors.semil.green,
            }}
          />
        </Tooltip>
      </div>
    );
  };

  renderOfStatus(status) {
    let label = "";
    let color = "";

    switch (status) {
      case "E":
        label = "Encerrado";
        color = Colors.info;
        break;

      case "P":
        label = "Produção";
        color = Colors.warning;
        break;

      case "C":
        label = "Cancelado";
        color = Colors.danger;
        break;

      default:
        label = "Não Identificado";
        status = "?";
        color = "grey";
        break;
    }

    if (label.length) {
      return (
        <div
          style={{
            marginRight: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title={label}>
            <div
              style={{
                width: 23,
                height: 23,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: color,
                borderRadius: 3,
                color: "white",
              }}
            >
              <b>{status}</b>
            </div>
          </Tooltip>
        </div>
      );
    }
  }

  getGroupedOfList(list) {
    let codeGrouped = {};

    [...list].forEach((of, key) => {
      of = of.item ? { ...of.item } : { ...of };

      if (of.ORD_OrdemProduto?.length) {
        let code = of.ORD_OrdemProduto.replace(/\s+/g, "");

        if (!codeGrouped[code]) {
          codeGrouped[code] = of;
        } else {
          codeGrouped[code].grouped = true;
          codeGrouped[code].quantity = true;
          codeGrouped[code].ORD_OrdemQuantidade += of.ORD_OrdemQuantidade;

          if (!codeGrouped[code].PRO_Descricao.includes("(Agrupado)")) {
            codeGrouped[
              code
            ].PRO_Descricao = `${codeGrouped[code].PRO_Descricao} (Agrupado)`;
          }
        }
      }
    });

    return Object.keys(codeGrouped).map((key) => codeGrouped[key]);
  }

  renderOf() {
    if (this.state.loadingOf) {
      return this.renderLoading();
    }

    if (!this.state.ofs.length) {
      return this.renderEmpty("Nenhuma OF encontrada.");
    }

    if (this.state.showList) {
      let renderArray = [];

      if (this.state.groupByCode) {
        renderArray = this.getGroupedOfList(this.state.ofList);
      } else {
        renderArray = [...this.state.ofList];
      }

      return (
        <div style={{ overflow: "scroll", height: "58vh", marginTop: 10 }}>
          {renderArray.map((of, key) => {
            of = of.item ? of.item : of;
            let selected = this.state.selectedOf
              ? this.state.selectedOf.ORD_OrdemSequencia ===
                of.ORD_OrdemSequencia
              : false;

            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 20,
                  borderRadius: selected ? 8 : 0,
                  borderBottom: "1px solid #999",
                  alignItems: "center",
                  backgroundColor: selected ? Colors.semil.green : "white",
                  color: selected ? "white" : "",
                }}
              >
                {this.renderOfActions(selected, of)}
                {this.renderOfTitle(of)}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div style={{ overflow: "scroll", height: "58vh", marginTop: 10 }}>
          {this.state.ofs.map((of, key) => {
            return (
              <TreeView
                spacing={20}
                selectedOf={this.state.selectedOf}
                rightIcon={(selected, doc) =>
                  this.renderOfActions(selected, doc)
                }
                getChildren={(doc) =>
                  doc.item ? doc.item.children : doc.children
                }
                data={this.state.ofs}
                renderTitle={(of) => this.renderOfTitle(of)}
              />
            );
          })}
        </div>
      );
    }
  }

  renderOfActions(selected, of) {
    of = of.item ? of.item : of;

    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {this.renderOfStatus(of.ORD_OrdemSituacao)}
        {this.renderSelectIcon(selected, of)}
      </div>
    );
  }

  renderOfTitle(of) {
    if (of.item) {
      of = of.item;
    }

    return (
      <p style={{ padding: 0, margin: 0 }}>
        <b>{of.ORD_OrdemProduto.replace(/\s+/g, "")}</b> - {of.PRO_Descricao} -{" "}
        <b>Qtd. {of.ORD_OrdemQuantidade}</b>
      </p>
    );
  }

  renderOfItems() {
    if (this.state.loadingOfItems) {
      return this.renderLoading();
    }

    if (!this.state.items.length) {
      return this.renderEmpty("Nenhum Item encontrado.");
    }

    return (
      <div style={{ overflow: "scroll", height: "37vh" }}>
        {this.state.items.map((of, key) => {
          let selected =
            of.filtered && of.filtered === true
              ? true
              : of.selected && of.selected === true
              ? true
              : false;
          let selectedItem = this.state.selectedItem
            ? this.state.selectedItem.ORD_OrdemSequencia ===
                of.item.ORD_OrdemSequencia &&
              this.state.selectedItem.ORD_OrdemPrvItemSeq ===
                of.item.ORD_OrdemPrvItemSeq
            : false;

          return (
            <ListItem
              onClick={() => {
                this.getItemTasks(of);
              }}
              button
              style={{
                border: selectedItem
                  ? `2px dotted ${selected ? `#ffff` : Colors.semil.green}`
                  : "0.3px solid lightgrey",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                boxShadow: Colors.boxShadow,
                backgroundColor: selected ? Colors.semil.green : "white",
                color: selected ? "white" : "",
                marginBottom: 10,
                borderRadius: 8,
              }}
            >
              <p style={{ padding: 0, margin: 0, maxWidth: "80%" }}>
                <b>{of.item.ORD_OrdemPrvItemProduto}</b> -{" "}
                {of.item.PRO_Descricao}
              </p>
              <b
                style={{ padding: 0, margin: 0, maxWidth: "20%" }}
              >{`Qtd. ${of.item.ORD_OrdemPrvItemQuantidade}`}</b>
            </ListItem>
          );
        })}
      </div>
    );
  }

  renderTaskActions(cod, seq, color) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Tooltip title={"Apontar Horário"}>
          <PlayArrow
            style={{
              color: color !== "white" ? "white" : Colors.semil.green,
              cursor: "pointer",
            }}
          />
        </Tooltip>
        <Tooltip title={"Pausar"}>
          <Pause
            style={{
              color: color !== "white" ? "white" : Colors.semil.green,
              cursor: "pointer",
            }}
          />
        </Tooltip>
        <Tooltip title={"Finalizar"}>
          <Stop
            style={{
              color: color !== "white" ? "white" : Colors.semil.green,
              cursor: "pointer",
            }}
          />
        </Tooltip>
        <Tooltip title={"Ver Apontamentos"}>
          <Visibility
            onClick={() => {
              this.handleAppointmentsModal(cod, seq);
            }}
            style={{
              color: color !== "white" ? "white" : Colors.semil.green,
              cursor: "pointer",
            }}
          />
        </Tooltip>
      </div>
    );
  }

  getOfTaskColor(apontamentos) {
    let color = "white";

    if (!apontamentos || !apontamentos.length) {
      color = Colors.danger;
    } else if (apontamentos && apontamentos.length) {
      let finalizado = false;

      for (let index = 0; index < apontamentos.length; index++) {
        const apontamento = apontamentos[index];

        if (apontamento.ORD_OrdemHoraQtdProduzida > 0 && !finalizado) {
          finalizado = true;
          break;
        }
      }

      if (finalizado) {
        color = Colors.semil.green;
      } else {
        color = Colors.info;
      }
    }

    return color;
  }

  renderItemTasks() {
    if (this.state.loadingItemTasks) {
      return this.renderLoading();
    }

    if (!this.state.tasks.length) {
      return this.renderEmpty("Nenhuma Atividade encontrada.");
    }

    return (
      <div style={{ overflow: "scroll", height: "37vh" }}>
        {this.state.tasks.map((task, key) => {
          let color = this.getOfTaskColor(task.item.apontamentos);

          return (
            <ListItem
              onMouseEnter={() => {
                this.setState({ hoveredTask: key });
              }}
              onMouseLeave={() => {
                this.setState({ hoveredTask: null });
              }}
              button={false}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                border: "0.3px solid lightgrey",
                boxShadow: Colors.boxShadow,
                backgroundColor: color,
                color: color !== "white" ? "white" : "",
                marginBottom: 10,
                borderRadius: 8,
              }}
            >
              <p
                style={{ padding: 0, margin: 0 }}
              >{`${task.item.ORD_OrdemPrvAtvCodigo} - ${task.item.MNV_AtividadeDescricao}`}</p>
              {this.state.hoveredTask === key
                ? this.renderTaskActions(
                    task.item.ORD_OrdemPrvAtvCodigo,
                    task.item.ORD_OrdemPrvAtvSeq,
                    color
                  )
                : null}
            </ListItem>
          );
        })}
      </div>
    );
  }

  renderLoading() {
    return (
      <div
        style={{ paddingTop: "20%", display: "flex", justifyContent: "center" }}
      >
        <ClipLoader size={35} color={Colors.semil.green} loading={true} />
      </div>
    );
  }

  handleExpand(array, value = null) {
    if (this.state.ofs.length) {
      let expandedAll = value === null ? !this.state.expandedAll : value;

      array = this.setTreeValue(array, expandedAll);

      this.setState({ expandedAll, ofs: array });
    }
  }

  setTreeValue(array, value) {
    for (let index = 0; index < array.length; index++) {
      array[index].open = value;

      if (array[index].children) {
        array[index].children = this.setTreeValue(array[index].children, value);
      } else if (array[index].item.children) {
        array[index].item.children = this.setTreeValue(
          array[index].item.children,
          value
        );
      }
    }

    return array;
  }

  filterTree(array, ids, field) {
    for (let index = 0; index < array.length; index++) {
      if (ids.includes(array[index][field])) {
        array[index].filtered = true;
      } else if (array[index].item && ids.includes(array[index].item[field])) {
        array[index].filtered = true;
      } else {
        array[index].filtered = false;
      }

      if (array[index].children) {
        array[index].children = this.filterTree(
          array[index].children,
          ids,
          field
        );
      } else if (array[index].item.children) {
        array[index].item.children = this.filterTree(
          array[index].item.children,
          ids,
          field
        );
      }
    }

    return array;
  }

  renderExpand() {
    return (
      <div style={{ marginRight: 10, paddingTop: 20 }}>
        <DefaultButton
          disabled={this.state.showList}
          leftIcon={<AspectRatio />}
          onClick={() => {
            this.handleExpand(this.state.ofs);
          }}
          title={!this.state.expandedAll ? "Expandir Tudo" : "Recolher Tudo"}
        />
      </div>
    );
  }

  renderPrint() {
    return (
      <div style={{ marginRight: 10, paddingTop: 20 }}>
        <DefaultButton
          leftIcon={<Print />}
          onClick={() => {
            this.printOfs(
              this.state.groupByCode
                ? this.getGroupedOfList(this.state.ofList)
                : this.state.ofList
            );
          }}
          title={""}
        />
      </div>
    );
  }

  renderGroupByCode() {
    return (
      <div style={{ marginRight: 10, paddingTop: 20 }}>
        <DefaultButton
          leftIcon={!this.state.groupByCode ? <FilterList /> : <FilterNone />}
          onClick={async () => {
            this.setState({ groupByCode: !this.state.groupByCode });
          }}
          title={
            !this.state.groupByCode
              ? "Agrupar por Código"
              : "Desagrupar Códigos"
          }
        />
      </div>
    );
  }

  renderShowList() {
    return (
      <div style={{ marginRight: 10, paddingTop: 20 }}>
        <DefaultButton
          leftIcon={!this.state.showList ? <List /> : <AccountTree />}
          onClick={async () => {
            await this.getOfList();
          }}
          title={!this.state.showList ? "" : ""}
        />
      </div>
    );
  }

  renderFilterAtv() {
    return (
      <div style={{ marginRight: 10, paddingTop: 20 }}>
        <DefaultButton
          leftIcon={<ArrowDropDown />}
          onClick={(evt) => {
            this.setState({ menuTarget: evt.currentTarget });
          }}
          title={`Atividades (${this.getFiltersApplied().atv_ids.length})`}
        />
      </div>
    );
  }

  renderFilterEngRecurso() {
    return (
      <div style={{ marginRight: 10, paddingTop: 20 }}>
        <DefaultButton
          leftIcon={<ArrowDropDown />}
          onClick={(evt) => {
            this.setState({ menuTargetEngRecurso: evt.currentTarget });
          }}
          title={`Recursos (${
            this.getFiltersApplied().eng_recursos_ids.length
          })`}
        />
      </div>
    );
  }

  renderFilterStatus() {
    return (
      <div style={{ marginRight: 10, paddingTop: 20 }}>
        <DefaultButton
          leftIcon={<ArrowDropDown />}
          onClick={(evt) => {
            this.setState({ menuTargetStatus: evt.currentTarget });
          }}
          title={`Status (${this.getFiltersApplied().situacao_ids.length})`}
        />
      </div>
    );
  }

  renderSearch() {
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <input
          onKeyDown={(evt) => {
            if (evt.key === "Enter") this.filterOf();
          }}
          onChange={(v) => {
            this.setState({ search: v.target.value });
          }}
          placeholder={"Código da OF..."}
          style={{
            padding: 15,
            paddingLeft: 50,
            width: "calc(100% - 140px)",
            fontSize: 17,
            boxShadow: Colors.boxShadow,
            border: "0.3px solid lightgrey",
            borderRadius: 8,
          }}
        />
        <Search
          style={{
            fontSize: 30,
            position: "absolute",
            left: 10,
            bottom: 10,
            color: Colors.semil.green,
          }}
        />
        <DefaultButton
          title={"Buscar"}
          loading={this.state.loadingOf}
          onClick={() => {
            this.filterOf();
          }}
          style={{ height: 52, marginLeft: 10, width: "130px" }}
          leftIcon={<Search />}
        />
      </div>
    );
  }

  renderFilterAtvMenu() {
    return (
      <Menu
        anchorEl={this.state.menuTarget}
        keepMounted
        open={Boolean(this.state.menuTarget)}
        onClose={() => {
          this.setState({ menuTarget: null });
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center" }}
        PaperProps={{
          style: {
            marginTop: "70px",
          },
        }}
      >
        {this.state.atividades.map((atividade, key) => {
          return (
            <MenuItem
              button={false}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Checkbox
                onClick={() => {
                  let atividades = this.state.atividades;
                  atividades[key].checked = !atividades[key].checked;

                  this.setState({ atividades });
                }}
                checked={atividade.checked}
                style={{ color: Colors.semil.green }}
              />
              <div>{`${atividade.item.ORD_OrdemPrvAtvCodigo} - ${atividade.item.MNV_AtividadeDescricao}`}</div>
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  renderFilterEngRecursoMenu() {
    return (
      <Menu
        anchorEl={this.state.menuTargetEngRecurso}
        keepMounted
        open={Boolean(this.state.menuTargetEngRecurso)}
        onClose={() => {
          this.setState({ menuTargetEngRecurso: null });
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center" }}
        PaperProps={{
          style: {
            marginTop: "70px",
          },
        }}
      >
        {this.state.eng_recursos.map((eng_recurso, key) => {
          return (
            <MenuItem
              button={false}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Checkbox
                onClick={() => {
                  let eng_recursos = this.state.eng_recursos;
                  eng_recursos[key].checked = !eng_recursos[key].checked;

                  this.setState({ eng_recursos });
                }}
                checked={eng_recurso.checked}
                style={{ color: Colors.semil.green }}
              />
              <div>{`${eng_recurso.item.ENG_RecursoCodigo} - ${eng_recurso.item.ENG_RecursoDescricao}`}</div>
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  renderFilterStatusMenu() {
    return (
      <Menu
        anchorEl={this.state.menuTargetStatus}
        keepMounted
        open={Boolean(this.state.menuTargetStatus)}
        onClose={() => {
          this.setState({ menuTargetStatus: null });
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center" }}
        PaperProps={{
          style: {
            marginTop: "70px",
          },
        }}
      >
        {this.state.status.map((item, key) => {
          return (
            <MenuItem
              button={false}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Checkbox
                onClick={() => {
                  let status = this.state.status;
                  status[key].checked = !status[key].checked;

                  this.setState({ status });
                }}
                checked={item.checked}
                style={{ color: Colors.semil.green }}
              />
              <div>{`${item.descricao}`}</div>
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  appointmentModal() {
    if (this.state.appointmentModal) {
      return (
        <div>
          {!this.state.loadingModal && !this.state.appointments.length ? (
            <p style={{ textAlign: "center", color: "grey" }}>
              Nenhum apontamento encontrado
            </p>
          ) : null}
          {this.state.appointments.map((appointment, key) => {
            let hours = moment
              .duration(
                moment(appointment.item.ORD_OrdemHoraDataFinal).diff(
                  moment(appointment.item.ORD_OrdemHoraDataInicio)
                )
              )
              .asHours();

            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 20,
                  borderRadius: 8,
                  marginBottom: 8,
                  boxShadow: Colors.boxShadow,
                  border: `0.5px solid ${Colors.semil.green}`,
                  alignItems: "center",
                  backgroundColor: "white",
                }}
              >
                <AccessTime
                  style={{ cursor: "pointer", color: Colors.semil.green }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 20,
                  }}
                >
                  <p style={{ padding: 0, margin: 0 }}>
                    <b>{appointment.item.EMP_RazaoSocial}</b> -{" "}
                    {appointment.item.CCT_Descricao}
                  </p>
                  <div>
                    {`De ${moment
                      .utc(appointment.item.ORD_OrdemHoraDataInicio)
                      .format("DD/MM/YYYY HH:mm")} até ${moment
                      .utc(appointment.item.ORD_OrdemHoraDataFinal)
                      .format("DD/MM/YYYY HH:mm")}`}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {`${hours > 0 ? hours.toFixed(4) : 0} horas`}
                    <b
                      style={{
                        fontSize: 20,
                        marginLeft: 4,
                        marginRight: 4,
                        paddingTop: 3,
                      }}
                    >{`${
                      appointment.item.ORD_OrdemHoraQtdProduzida > 0
                        ? ` • `
                        : ""
                    }`}</b>
                    <b style={{ color: Colors.semil.green }}>{`${
                      appointment.item.ORD_OrdemHoraQtdProduzida > 0
                        ? `Atividade Concluída`
                        : ""
                    }`}</b>
                  </div>
                </div>
              </div>
            );
          })}
          <div style={{ paddingTop: 50 }}>
            <DefaultButton
              onClick={() => {
                this.setState({ appointmentModal: false, appointments: [] });
              }}
              width={"100%"}
              title={"Fechar"}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    return this.state.loading ? (
      <DefaultLoader />
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: 25,
          backgroundColor: "white",
          borderRadius: 8,
          boxShadow: Colors.boxShadow,
        }}
      >
        <div
          style={{
            width: "60%",
            height: "88vh",
            borderRight: "0.3px solid lightgrey",
            padding: 20,
          }}
        >
          <div>
            <h2 style={{ padding: 0, margin: 0, paddingBottom: 20 }}>OF</h2>
            {this.renderSearch()}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {this.state.ofs.length && this.state.atividades.length
                ? this.renderShowList()
                : null}
              {this.state.ofs.length &&
              this.state.atividades.length &&
              this.state.showList
                ? this.renderGroupByCode()
                : null}
              {this.state.ofs.length && this.state.showList
                ? this.renderPrint()
                : null}
              {this.state.ofs.length ? this.renderExpand() : null}
              {this.state.ofs.length ? this.renderFilterAtv() : null}
              {this.state.ofs.length ? this.renderFilterStatus() : null}
              {this.state.ofs.length ? this.renderFilterEngRecurso() : null}
            </div>
            {!this.state.search && !this.state.loadingOf
              ? this.renderEmpty("Pesquise por uma OF para começar.")
              : this.renderOf()}
          </div>
        </div>
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            height: "88vh",
          }}
        >
          <div
            style={{
              height: "50%",
              borderBottom: "0.3px solid lightgrey",
              padding: 20,
            }}
          >
            <h2 style={{ padding: 0, margin: 0, paddingBottom: 20 }}>Itens</h2>
            {this.renderOfItems()}
          </div>
          <div style={{ height: "50%", padding: 20 }}>
            <h2 style={{ padding: 0, margin: 0, paddingBottom: 20 }}>
              Atividades
            </h2>
            {this.renderItemTasks()}
          </div>
        </div>
        {this.renderFilterAtvMenu()}
        {this.renderFilterStatusMenu()}
        {this.renderFilterEngRecursoMenu()}
        <DefaultModal
          loading={this.state.loadingModal}
          content={this.appointmentModal()}
          title={"Apontamentos"}
          onClose={() => {
            this.setState({ appointmentModal: false });
          }}
          open={this.state.appointmentModal}
        />
      </div>
    );
  }
}
