import React from "react";

export default class Functions {
  static getEndPoint() {
    /* return "http://localhost:5001/teste-semil/us-central1"; */
    return "https://us-central1-teste-semil.cloudfunctions.net";
    /* return "_self" in React.createElement("div")
      ? "http://localhost:5001/semil-connect/us-central1"
      : "https://us-central1-teste-semil.cloudfunctions.net"; */
    /* ('_self' in React.createElement('div')) ? 'http://localhost:5001/semil-connect/us-central1' : 'https://us-central1-semil-connect.cloudfunctions.net'; */
  }

  static getFunctionEndPoint() {
    return "https://us-central1-teste-semil.cloudfunctions.net";
  }
}
