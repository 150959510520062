import React, { Component, forwardRef } from "react";
import DefaultLoader from "../components/DefaultLoader";
import Colors from "../constants/Colors";
import {
  Card,
  Button,
  List,
  ListItem,
  TextareaAutosize,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DescriptionIcon from "@material-ui/icons/Description";
import Firestore from "../api/firebase/Firestore";
import Storage from "../api/firebase/Storage";
import moment from "moment";
import SessionHelper from "../helper/SessionHelper";
import SATHelper from "../helper/SATHelper";
import DefaultButton from "../components/DefaultButton";
import DefaultInput from "../components/DefaultInput";
import DefaultModal from "../components/DefaultModal";

export default class CustomerAppointmentPage extends Component {
  state = {
    docs: [],
    orderDocs: [],
    userDocs: [],
    company: null,
    panel: "open",
    text: "",
    description: "",
    loading: true,
    activeSAT: null,
    addModal: false,
    loadingModal: false,
  };

  async componentDidMount() {
    await this.getUsers();
    await this.getOrders();
    await this.getCompany();
    await this.getDocs();

    if (this.messagesEnd) {
      this.scrollToBottom();
    }
  }

  componentDidUpdate() {
    if (this.messagesEnd) {
      this.scrollToBottom();
    }
  }

  async getOrders() {
    let query = await Firestore.customQuery("order")
      .where("billing_cnpj", "==", parseInt(SessionHelper.getData().cnpj))
      .get();
    let docs = [];

    query.forEach((doc, key) => {
      let data = doc.data();
      data.id = doc.id;

      docs.push(data);
    });

    this.setState({ orderDocs: docs });
  }

  async getUsers() {
    let query = await Firestore.customQuery("user").get();
    let docs = [];

    query.forEach((doc, key) => {
      let data = doc.data();
      data.id = doc.id;

      docs.push(data);
    });

    this.setState({ userDocs: docs });
  }

  async getCompany() {
    console.log(SessionHelper.getData().cnpj);
    let query = await Firestore.customQuery("company")
      .where("cnpj", "==", parseInt(SessionHelper.getData().cnpj))
      .get();
    let data = null;

    if (query.size === 1) {
      data = query.docs[0].data();
      data.id = query.docs[0].id;
    }

    this.setState({ company: data });
  }

  async getDocs(loading = false) {
    if (loading) {
      this.setState({ loading: true });
    }

    let query = await Firestore.customQuery("appointment")
      .where("company_id", "==", this.state.company.id)
      .orderBy("start_date", "desc")
      .get();
    let docs = [];

    query.forEach((doc, key) => {
      let data = doc.data();
      data.id = doc.id;

      if (this.state.panel === "open" && data.status === "open") {
        docs.push(data);
      } else if (
        this.state.panel === "closed" &&
        (data.status === "closed" || data.status === "archived")
      ) {
        docs.push(data);
      }
    });

    this.setState({ docs: docs, loading: false });
  }

  async handlePanelClick(type) {
    await this.setState({ panel: type, text: "", activeSAT: null, docs: [] });
    await this.getDocs(true);
  }

  async uploadFile(file) {
    if (file) {
      try {
        let satState = this.state.activeSAT;
        satState.history.push({
          id_user: SessionHelper.getFirebaseAuth().uid,
          message: "Usuário anexou arquivo",
          date: Firestore.dateToFirestamp(new Date()),
          file: URL.createObjectURL(file),
        });

        this.setState({ activeSAT: satState });

        let ref = await Storage.uploadFile("sat_attatchments", file);
        let downloadPath = await Storage.getFileFromRef(ref);

        if (!downloadPath) {
          throw new Error("upload error");
        }

        let data = {
          extension: file.name.split(".").pop(),
          name: file.name,
          size: file.size,
          date: new Date(),
          link: downloadPath,
          id_user: SessionHelper.getFirebaseAuth().uid,
        };

        let sat = this.state.activeSAT;
        sat.attachments.push(data);

        await Firestore.update(
          { attachments: sat.attachments },
          "appointment",
          sat.id
        );
        await SATHelper.historyIteration(
          this.state.activeSAT.id,
          SessionHelper.getFirebaseAuth().uid,
          "Usuário anexou arquivo",
          Firestore.dateToFirestamp(new Date()),
          downloadPath
        );
      } catch (error) {}
    }
  }

  renderPanel() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          paddingRight: 18,
          marginBottom: 15,
        }}
      >
        <Button
          onClick={() => {
            this.handlePanelClick("open");
          }}
          variant="outlined"
          style={{
            color: this.state.panel === "open" ? "#fff" : Colors.semil.green,
            backgroundColor:
              this.state.panel === "open" ? Colors.semil.green : "#fff",
            borderColor: Colors.semil.green,
            borderWidth: 2,
          }}
        >
          Em Aberto
        </Button>
        <Button
          onClick={() => {
            this.handlePanelClick("closed");
          }}
          variant="outlined"
          style={{
            color: this.state.panel === "closed" ? "#fff" : Colors.semil.green,
            backgroundColor:
              this.state.panel === "closed" ? Colors.semil.green : "#fff",
            borderColor: Colors.semil.green,
            borderWidth: 2,
          }}
        >
          Finalizadas
        </Button>
      </div>
    );
  }

  renderChat() {
    return (
      <div style={{ width: "100%" }}>
        <div style={{ height: 650, overflowY: "scroll" }}>
          {this.state.activeSAT.history.map((message, key) => {
            let sender =
              this.state.userDocs[
                this.state.userDocs.findIndex(
                  (item) => item.id === message.id_user
                )
              ];

            return (
              <div
                style={{
                  paddingLeft: 15,
                  paddingRight: 12,
                  paddingTop:
                    (this.state.activeSAT.history[key - 1] &&
                      !this.state.activeSAT.history[key - 1].user_id) ||
                    sender.id !== SessionHelper.getFirebaseAuth().uid
                      ? 12
                      : 0,
                  display: "flex",
                  flexDirection:
                    sender.id !== SessionHelper.getFirebaseAuth().uid
                      ? "row"
                      : "row-reverse",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    marginTop: 20,
                    backgroundColor:
                      sender.id !== SessionHelper.getFirebaseAuth().uid
                        ? "rgb(245, 245, 245)"
                        : Colors.semil.green,
                    boxShadow: "rgba(50, 50, 50, 0.47) 1px 1px 10px 0px",
                    borderRadius: 10,
                    minHeight: 48,
                    width: "80%",
                    marginBottom: 12,
                    wordWrap: "break-word",
                  }}
                >
                  <p
                    style={{
                      padding: 8,
                      color:
                        sender.id !== SessionHelper.getFirebaseAuth().uid
                          ? "black"
                          : "#fff",
                    }}
                  >
                    {message.message}
                  </p>
                  {message.file && (
                    <DescriptionIcon
                      onClick={() => {
                        window.open(message.file);
                      }}
                      style={{
                        cursor: "pointer",
                        color:
                          sender.id !== SessionHelper.getFirebaseAuth().uid
                            ? Colors.semil.green
                            : "#fff",
                        fontSize: 50,
                        width: "100%",
                      }}
                    />
                  )}
                  <p
                    style={{
                      fontSize: 10,
                      fontStyle: "italic",
                      paddingLeft: 15,
                      paddingRight: 15,
                      color:
                        sender.id !== SessionHelper.getFirebaseAuth().uid
                          ? "black"
                          : "#fff",
                      float: "left",
                    }}
                  >{`${
                    sender.id === SessionHelper.getFirebaseAuth().uid
                      ? "Você, "
                      : sender.name
                      ? `${sender.name}`
                      : ""
                  } ${moment(
                    typeof message.date == "string"
                      ? message.date
                      : message.date.toDate()
                  ).format("DD/MM/YYYY HH:mm")}`}</p>
                </div>

                {(this.state.activeSAT.history[key - 1] &&
                  !this.state.activeSAT.history[key - 1].user_id) ||
                sender.id !== SessionHelper.getFirebaseAuth().uid ? (
                  <img
                    src={
                      !sender.photo
                        ? "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSBFTlkikWa2vJOC88Yet3MiaVIqZbftnPCx3xH6CP0AYb-SKsl&usqp=CAU"
                        : sender.photo
                    }
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: 25,
                      boxShadow: "rgba(50, 50, 50, 0.47) 1px 1px 10px 0px",
                      marginRight:
                        sender.id !== SessionHelper.getFirebaseAuth().uid
                          ? 0
                          : 20,
                      marginLeft:
                        sender.id !== SessionHelper.getFirebaseAuth().uid
                          ? 20
                          : 0,
                    }}
                  />
                ) : null}
              </div>
            );
          })}

          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div>
        </div>
        <div
          style={{
            height: 60,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 10,
          }}
        >
          <input
            disabled={this.state.activeSAT.status === "open" ? false : true}
            ref={(ref) => (this.input = ref)}
            onBlur={(evt) => {
              this.setState({ text: evt.target.value });
            }}
            onKeyDown={async (evt) => {
              if (evt.keyCode === 13) {
                await this.setState({ text: this.input.value });
                this.sendMessage();
              }
            }}
            placeholder={"Escreva uma mensagem..."}
            style={{
              fontSize: 15,
              padding: 8,
              width: "100%",
              borderRadius: 15,
              borderColor: "transparent",
              border: "0.3px solid lightgrey",
            }}
          />
          <SendIcon
            onClick={() => {
              this.sendMessage();
            }}
            style={{
              position: "relative",
              right: 0,
              left: -38,
              color: Colors.semil.green,
              cursor: "pointer",
            }}
          />

          <input
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            onChange={(v) => {
              this.uploadFile(v.target.files[0]);
            }}
            disabled={this.state.activeSAT.status === "open" ? false : true}
          />
          <label htmlFor="contained-button-file">
            <AttachFileIcon
              id={"contained-button-file"}
              style={{
                position: "relative",
                right: 0,
                top: 3,
                left: -95,
                color: Colors.semil.green,
                cursor: "pointer",
                transform: "rotate(45deg)",
              }}
            />
          </label>
        </div>
      </div>
    );
  }

  conversationClick(sat) {
    if (this.input) {
      this.input.value = "";
    }
    this.setState({ activeSAT: sat, text: "" });
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  async sendMessage() {
    if (this.state.text !== "") {
      let text = this.state.text;

      let activeSAT = this.state.activeSAT;
      activeSAT.history.push({
        id_user: SessionHelper.getFirebaseAuth().uid,
        message: text,
        date: Firestore.dateToFirestamp(new Date()),
        file: "",
      });

      this.input.value = "";
      this.setState({ activeSAT: activeSAT, text: "" });

      await SATHelper.historyIteration(
        activeSAT.id,
        SessionHelper.getFirebaseAuth().uid,
        text
      );
    }
  }

  async addSat() {
    this.setState({ loadingModal: true });

    try {
      let data = {
        answered_satisfaction_research: false,
        attachments: [],
        company_id: this.state.company.id,
        end_date: null,
        estimated_end_date: null,
        history: [],
        hour_pricing_id: null,
        order_id: null,
        priority: "normal",
        satisfaction_research_id: "",
        start_date: new Date(),
        status: "open",
        technicians: [],
        order_items: [],
        title: `Assistência #${moment().format("DDMMYYYYHHmmss")}`,
        user_id: SessionHelper.getFirebaseAuth().uid,
      };

      let doc = await Firestore.getId("appointment");

      await Firestore.insert(data, "appointment", doc);
      await SATHelper.historyIteration(
        doc,
        data.user_id,
        "Usuário criou a SAT"
      );

      if (this.state.description.length > 0) {
        await SATHelper.historyIteration(
          doc,
          data.user_id,
          this.state.description
        );
      }

      await this.setState({ loadingModal: false, addModal: false });
      await this.getDocs(true);
    } catch (error) {}
  }

  card(sat, key) {
    return (
      <ListItem
        button
        onClick={() => {
          this.conversationClick(sat);
        }}
        style={{
          backgroundColor:
            this.state.activeSAT && this.state.activeSAT.id === sat.id
              ? "#ededed"
              : "",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: 0,
          borderTop: "1px solid lightgrey",
          borderBottom:
            this.state.docs.length - 1 === key ? "1px solid lightgrey" : "",
          padding: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: "bold", color: Colors.semil.green }}>
            {sat.title}
          </div>
        </div>

        {sat.order_id ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{}}>{`Pedido nº ${
              this.state.orderDocs[
                this.state.orderDocs.findIndex(
                  (order) => order.id === sat.order_id
                )
              ].order_code
            }`}</div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{}}>{`Nenhum pedido vinculado`}</div>
          </div>
        )}

        {sat.estimated_end_date ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{}}>{`Previsão para ${moment(
              sat.estimated_end_date.toDate()
            ).format("DD/MM/YYYY HH:mm")}`}</div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{}}>{`Sem previsão ainda`}</div>
          </div>
        )}

        <div style={{ color: sat.status === "open" ? "green" : "red" }}></div>
        <div
          style={{
            alignSelf: "flex-end",
            paddingTop: 10,
            fontStyle: "italic",
            fontSize: 12,
            color: "grey",
          }}
        >
          {moment(sat.start_date.toDate()).format("DD/MM/YYYY HH:mm")}
        </div>
      </ListItem>
    );
  }

  renderCards() {
    if (!this.state.docs.length) {
      return (
        <div style={{ marginTop: 300, textAlign: "center", color: "grey" }}>
          {"Nenhuma assistência encontrada"}
        </div>
      );
    } else {
      return (
        <List>
          {this.state.docs.map((sat, key) => {
            return this.card(sat, key);
          })}
        </List>
      );
    }
  }

  addSatModal() {
    if (this.state.addModal) {
      return (
        <div>
          <DefaultInput
            disabled
            value={moment().format("DD/MM/YYYY HH:mm")}
            label={"Data Abertura"}
          />
          <TextareaAutosize
            rowsMax={8}
            rowsMin={8}
            placeholder="Descreva o problema, se possível insira o número e modelo do equipamento e seu ano de fabricação."
            style={{ width: "100%", fontSize: "inherit" }}
            onBlur={(evt) => {
              this.setState({ description: evt.target.value });
            }}
          />

          <div
            style={{
              alignSelf: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 15,
            }}
          >
            <Button
              onClick={() => {
                this.addSat();
              }}
              style={{
                fontWeight: "bold",
                backgroundColor: Colors.semil.green,
                color: "#fff",
                width: "48%",
                marginRight: "2%",
              }}
              variant={"contained"}
            >
              {"CONFIRMAR"}
            </Button>
            <Button
              onClick={() => {
                this.setState({ addModal: false });
              }}
              style={{ width: "48%", fontWeight: "bold", marginLeft: "2%" }}
              variant={"contained"}
            >
              {"CANCELAR"}
            </Button>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div style={styles.container}>
        <DefaultButton
          title={"Novo Chamado"}
          onClick={() => {
            this.setState({ addModal: true });
          }}
        />
        <div style={{ paddingTop: 20 }}>
          <Card
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              marginBottom: 15,
              paddingBottom: 40,
            }}
          >
            <p style={{ fontSize: 18, fontWeight: "bold" }}>
              {"Assistência Técncia"}
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 700,
              }}
            >
              <div style={{ width: "28%" }}>
                {this.renderPanel()}

                <div style={{ overflowY: "scroll", height: "100%" }}>
                  {this.state.loading ? (
                    <div style={{ marginTop: 300 }}>
                      <DefaultLoader />
                    </div>
                  ) : (
                    this.renderCards()
                  )}
                </div>
              </div>

              <div style={{ borderLeft: "0.1px lightgrey solid" }} />

              <div
                style={{
                  width: "88%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {this.state.activeSAT ? this.renderChat() : null}
              </div>
            </div>
          </Card>
        </div>
        <DefaultModal
          loading={this.state.loadingModal}
          content={this.addSatModal()}
          title={"Novo Chamado"}
          onClose={() => {
            this.setState({ addModal: false });
          }}
          open={this.state.addModal}
        />
      </div>
    );
  }
}

const styles = {
  container: {
    padding: 25,
  },
};
